import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'


export default function JelentkezesInfoModal(props: {open: boolean, setOpen: (open: boolean) => void}) {
	const cancelButtonRef = useRef(null)
	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Jelentkezés önálló tanulásra
										</Dialog.Title>
										
										<div className="mt-2">
											<p className="text-sm text-gray-500 pt-2">
												 A 'Tovább' gombra kattintva átirányítunk a tananyag felületére, a <a href="http://tanulas.lehetkonnyebb.hu" className="text-blue-600 hover:underline outline-none">tanulas.lehetkonnyebb.hu</a> oldalra.
												 <div className="h-[10px]" />
												 Itt elvégezheted regisztrációt, és a sikeres regisztráció után ugyanezen a felületeten fogod megtalálni a tananyagot is.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<a
									href="http://tanulas.lehetkonnyebb.hu/signup"
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 hover:bg-blue-400 text-base font-medium text-white focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
								>
									Tovább
								</a>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => props.setOpen(false)}
									ref={cancelButtonRef}
								>
									Mégsem
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
