export const Title = (props: { children: React.ReactNode; id: string }) => (
  <h2
    className="text-3xl font-bold font-display mb-8"
    id={props.id}
  >
  <span className="leading-[57px] block sm:inline sm:px-[20px] sm:py-[10px] py-0 px-4 sm:mx-0 -mx-2" style={{ 
      color: "#ffffff",
      backgroundColor: "rgb(128, 85, 113)",
      textTransform: "uppercase", 
      fontWeight: 600}}
> 

    {props.children}
    </span>
  </h2>
);
export const Subtitle = (props: {
  children: React.ReactNode;
  className?: string;
}) => (
  <h3
    className={"my-5 text-xl mt-8 mb-2 " + props.className}

  >
    <span style={{
      color: "#5C92E4",
      paddingBottom: "3px",
      fontSize: "1.2em",
      fontWeight: 600,
      lineHeight: "1.5em",
      marginBottom: "30px",
    }}
> 

    {props.children}
    </span>
  </h3>
);
export const Text = (props: {
  children: React.ReactNode;
  className?: string;
}) => (
  <p className={"text-base leading-relaxed mb-2 " + props.className}>
    {props.children}
  </p>
);
