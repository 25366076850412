const gyik =[
	{
		q: "Tényleg teljes egészében díjmentes a #Lehet könnyebb program?",
		a: <span>A program fő célja, hogy a lehető legtöbb embernek biztosítsunk lehetőséget arra, hogy magabiztosabbnak érezze magát, 
			legyen szó akár a digitális munkakörnyezetbe való beilleszkedésről, új pénzügyi eszközök használatáról, vagy online álláskeresésről. 
			Ezért a <strong>program teljes egészében térítésmentes</strong>, viszont a részvételhez jelentkezéskor szükséges megerősítened, hogy elfogadod 
			az Adatkezelési Nyilatkozatot. </span>
	},
	{
		q: "Az online tananyag utólag is elérhető/visszanézhető lesz a résztvevők számára?",
		a: <span>Mindhárom témakörhöz kapcsolódóan az online anyagokhoz 2022. december 31-ig szabadon biztosítunk hozzáférést minden résztvevő számára. 
			Ebben az időszakban annyiszor és olyan ütemezésben nézheted meg és használhatod az itt található anyagokat, ahogy az időd, életkörülményeid azt megengedik, 
			vagy megkívánják.  </span>
	},
	{
		q: "Milyen segítséget kapnak a résztvevők?",
		a: <span>
			Az online tananyagokat olyan ütemben és módon dolgozod fel önállóan, ahogy Neked kényelmes, de plusz segítségre is számíthatsz.<strong> Digitális munkakörnyezet </strong>témakörben a tananyagon belül csatlakozhatsz egy közös Microsoft Teams felületre, ahol akár a tanulótársakkal 
			is egyeztethetsz, <strong> pénzügyi tudatosság</strong> témakörünkkel kapcsolatban - amennyiben további kérdéseid lennének - e-mailben veheted fel a kapcsolatot egy szakértővel. 
			Mindezekről bővebb információt az egyes tananyagoknál találhatsz.


			{/* <strong>A 2021. december 4-i</strong> képzési napon, <strong>digitális munkakörnyezet</strong> témakörünkkel kapcsolatban -  
			amennyiben jelentkeztél és bejutottál a mentorált kurzusra - úgy igen, <strong>4 órás mentorált, 
			online, kiscsoportos</strong> képzésen vehetsz részt. <br/>Ugyanezen a napon pénzügyi tudatosság és munkavállalói készségek 
			témakörben is lesz 1-1 órás tájékoztató a mentorált képzésre bejutottak számára, a nap tervezett programját 
			 „A programról” menüpontban láthatod.<br/>

			Ezeken felül <strong>pénzügyi tudatosság</strong> témakörünkkel kapcsolatban mentoraink e-mailben várják a résztvevők kérdéseit. 
			A mentorok elérhetősége az online tananyagnál kerül feltüntetésre.<br/>
            <strong>Munkavállalói készségek</strong>kel kapcsolatban pedig minden második héten, igény szerint
			előre leegyeztetett időpontban, 1 órás tréning keretében segítünk felkészülni 
			a munkaerőpiaci helyzetben való eligaagzodásra. Az online fogadóórához történő csatlakozás linkjei és időpontjai 
			az online tananyagnál érhetőek el. */}


		    
		  <br/>
			</span>
	},
	{
		q: "Mennyire kell interaktív kurzusra számítani? Szükséges hozzá mikrofon, hangszóró, fejhallgató, webkamera?",
		a: <span>
			A tananyag átnézéséhez a hangszórón/fejhallgatón kívül nem szükséges mikrofon vagy webkamera, 
			de ha szeretnéd felvenni a kapcsolatot a Microsoft Teams felületen a többi résztvevővel, akkor javasolt ezek megléte is.
			{/* Mentorált kurzus során a képzési időben a csoportod tagjaival is együtt fogsz dolgozni, 
			ehhez mikrofonra (vagy fejhallgatóra) feltétlenül szükség lesz és javasolt a webkamera használata is. */} 
			
			
			</span>
	},
	{
		q: "Milyen laptop/számítógép szükséges az online tananyagokhoz?",
		a: <span>Preferáltan legalább kétmagos processzorral és 4 GB memóriával rendelkező gépre van szükség. 
			Operációs rendszerként Windows&nbsp;7 / Windows&nbsp;10 / Windows&nbsp;11 javasolt, mert azon érhetőek el (akár ingyenesen) azok a platformok, 
			amelyekkel a kurzus során foglalkozni fogunk. Linux operációs rendszer alól is átnézhetőek tananyagok (de pl. a MS Teams-hez való kapcsolódásnál
			a Teams applikáció nem fut Linux alatt, de a böngészőből Linuxon keresztül is kipróbálható az MS Teams legtöbb funkciója). </span>
	},
	{
		q: "Szükséges vizsgát tenni a kurzus végén?",
		a: <span><strong>Nem</strong>. A program egyetlen és legfontosabb célja az ismeret átadása, annak érdekében, 
		hogy a résztvevők élethelyzetén könnyíteni tudjunk. </span>
	},
	{
		q: "A kurzus végén kapnak a résztvevők bármilyen oklevelet/ bizonyítványt/ tanúsítványt?",
		a: <span>A program végén egy általunk kiállított tanúsítvánnyal, illetve a megszerzett tudással leszel gazdagabb. :-) </span>
		
	},
	{
		q: "A résztvevők kapnak segítséget az elhelyezkedésben a program után?",
		a: <span>Ez a program leginkább arra szolgál, hogy olyan alapkészségek birtokába juss, 
			amelyek könnyebbé tehetik az elhelyezkedésedet, de konkrét munkalehetőséget nem biztosít.</span>
	},
]

export default gyik