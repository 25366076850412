import { useEffect } from "react";
import { useLocation } from "react-router";
import {
  Banner,
  Rolunk,
  Programrol,
  Jelentkezes,
  TanulasiPortal,
  LehetKonnyebb,
  Gyik,
} from "./";
const Main = () => {
  const location = useLocation();
  const anchor = (location.state as any )?.anchor;
  useEffect(() => {
    if (anchor) goToAnchor(anchor);
  } , [anchor]);
  
  return (
    <main>
      <Banner />
      <LehetKonnyebb />
      <Programrol />
      <Jelentkezes />
      <TanulasiPortal />
      <Rolunk />
      <Gyik />
    </main>
  );
};

export const goToAnchor = (id: string) => {
	const el = document.getElementById(id);
	if (el)
		window.scrollTo({
			behavior: "smooth",
			top:
				el.getBoundingClientRect().top +
				window.scrollY - 100,
		});
}

export default Main;
