import { Subtitle } from "./typography";

const Footer = () => {
  return (
    <footer className="bg-[#4e233f]">
      <div className="container max-w-screen-lg text-center leading-relaxed">
        <div className="pt-4 pb-16 text-white">
          <Subtitle className="mt-0 mb-4 text-2xl font-small">
            Kérdésed van?
          </Subtitle>

            Javasoljuk, hogy olvasd el a GYIK (Gyakran ismételt kérdések)
            oldalunkat
          , de ha nem találsz ott választ, akkor írj nekünk a{" "}

            <a
              className="underline whitespace-nowrap text-[#7aafff]"
              href="mailto:hello@lehetkonnyebb.hu"
            >
              hello@lehetkonnyebb.hu
            </a>
{" "}
          e-mail címre!
          <br/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
