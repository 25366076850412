import Section from "./Section";
import { Title, Subtitle, Text } from "./typography";
import gyik from "../assets/gyik";
import React from "react";

const Gyik = () => {
  return (
    <Section dark>
      <Title id="gyik">Gyakran ismételt kérdések</Title>

      {gyik.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Subtitle>{item.q}</Subtitle>
            <Text>{item.a}</Text>
          </React.Fragment>
        );
      })}
    </Section>
  );
};

export default Gyik;
