import Section from "./Section";
import { Text, Title, Subtitle } from "./typography";

const LehetKonnyebb = () => {
  return (
    <Section>
      <Title id="lehet-konnyebb">Lehet könnyebb</Title>
      <Text>
        Az elmúlt időszakban mindannyiunknak sok mindent újra- és át kellett
        gondolnunk az élet számos területén. Új módok, lehetőségek, kihívások
        jelentek meg nem csak a munkahelyünkkel, munkakörnyezetünkkel, hanem az
        álláskereséssel, vagy akár pénzügyi helyzetünkkel kapcsolatban is.
      </Text>
      <Text>
        A digitális létre, online álláskeresésre, munkavégzésre történő átállás,
        valamint annak átgondolása, hogy hogyan tudjuk tudatosan kezelni pénzügyeinket,
        a kihívások mellett sok újdonságot hozott magával,
        melyekhez mindannyiunknak alkalmazkodnunk kell.
      </Text>
      <Text>
        Ehhez a felfedezőúthoz kíván segítséget nyújtani az OTP Bank, a HTTP Alapítvány, a Microsoft Magyarország,
        és a Trenkwalder Recruitment közös,
        <b>„Lehet könnyebb” térítésmentes, online</b> képzési projektje.
      </Text>

      <Subtitle>Ha úgy érzed</Subtitle>
      <ul className="list-disc list-inside">
              <li>
          bizonytalan vagy az online digitális munkakörnyezetekkel kapcsolatban
        </li>
        <li>szívesen lennél tudatosabb pénzügyeid terén</li>
        <li>
          állást keresel / állást váltanál, de nem ismered a mostani trendeket
        </li>  
      </ul>
      <Text className="mt-2">
        akkor <strong>csatlakozz</strong> térítésmentes, online képzési programunkhoz, aminek elvégzése után mindez{" "}
        <strong>lehet könnyebb</strong>!{" "}
      </Text>

      {/* <Button className="mt-4">Részletek a programról</Button> */}
    </Section>
  );
};

export default LehetKonnyebb;
