import { Title, Text, Subtitle } from "./typography";
import Section from "./Section";
import React from "react";

const Temakor = (props: { title: React.ReactElement, left?: React.ReactElement, right?: React.ReactElement, flipped?: boolean, noBottomLine?: boolean, noTopLine?: boolean, small?: boolean}) => {
	return <div className={`flex flex-col lg:flex-row lg:items-center py-8 lg:px-4 relative box-border ${props.small ? 'lg:h-[120px]' : 'lg:h-[280px]'}`}>
				{ !props.noTopLine && <Line top={-140} flipped={props.flipped} />}
				<div className="p-6 text-center w-full lg:w-[33%] order-2 lg:order-1 text-[15px]">
					{props.left}
				</div>
				<div className="bg-[#623753] text-white border-4 inline-block py-8 px-2 lg:px-12 rounded-full text-3xl text-center font-semibold leading-[1.4] flex-1 order-1 lg:order-2">
					{props.title}
				</div>
				<div className="p-6 text-center w-full lg:w-[33%] order-3 text-[15px]">
					{props.right}
				</div>
				{ !props.noBottomLine && <Line top={140} flipped={!props.flipped} />}
			</div>
}

const Line = (props: {top?: number, flipped?: boolean, small?: boolean}) => {
	const height = props.small ? 80 : 144
	return <div className={`w-[calc(100%-2rem)] h-[144px] absolute hidden lg:flex`} style={{top: props.top || 0}}>
				<div className={`w-[200px] border-[#5891DB] border-l-4 ${props.flipped ? 'border-t-4 rounded-tl-full  translate-y-[calc(100%-4px)]' : 'border-b-4 rounded-bl-full'}`} style={{height: 144}}></div>
				<div className="flex-1 border-[#5891DB] border-b-4"></div>
				<div className={`w-[200px] border-[#5891DB] ${props.flipped ? 'border-r-4 border-b-4 rounded-br-full' : 'border-r-4 border-t-4 rounded-tr-full translate-y-[calc(100%-4px)]'}`} style={{height: height, marginTop: 144-height}}></div>
			</div>
}

const Programrol = () => {
	return (
		<>
		<Section dark className="pb-0" innerClassName="pb-1">
			<Title id="programrol">A programról</Title>

			<Text>
				Szeretnél biztosabb tudást szerezni az online együttműködést és közös munkavégzést lehetővé tévő eszközök
				használatában? Jól jönne néhány hasznos tanács a pénzügyeid intézéséhez? Szeretnél a munkaerőpiacon könnyebben elhelyezkedni? 
				Térítésmentes, magyar nyelvű, online tananyagaink segítségedre lehetnek. <br />
			</Text>
			<Subtitle>Kinek szól a program?</Subtitle>
			<Text>
				<strong>Mindenkinek</strong>. Ha munkanélküli vagy, vagy esetleg úgy látod, hogy
				mindenképpen munkahelyet kell váltanod, akkor segítünk az
				elindulásban. Ötleteket adunk, hogyan készítsd el az önéletrajzodat, <strong>felkészítünk arra, mi vár rád egy
				állásinterjún</strong>, hogyan tudod növelni az „eladhatóságodat”. <br />
				Megmutatjuk, hogyan tudsz online, akár otthonról vagy hibrid munkakörnyezetben is részt venni a
				munkában, <strong>miként tudsz távolról, online együttműködni</strong> az iroda többi
				dolgozójával. <br />
				Mindezeken felül <strong>segítséget adunk a pénzügyeid kezeléséhez</strong>, hogy a
				megszerzett jövedelmedet tudatosabban kezeld. <br/>
				Ha ezek közül akár csak egy téma is érdekel, vagy akár együtt mindegyik, 
				akkor ez a program neked szól.<br />
				<strong>A programon való részvételhez semmilyen előképzettségre nincs szükség.</strong>	{" "}
				<br />
			</Text>

			<Subtitle>Miért érdemes belevágnod?</Subtitle>
			<Text>
				<strong>A képzésünk teljesen térítésmentes</strong>. Ennek oka, hogy
				Magyarországon meghatározó szereppel bíró cégek álltak az ügy mögé a
				saját erőforrásaikkal társadalmi felelősségvállalásuk keretén belül.
			</Text>

			<Text>
				<strong>Magyar nyelvű</strong>. Az interneten mindenről van tananyag, azonban
				ezek jelentős része idegen nyelven érhető el, és sok esetben a külföldi
				helyzethez igazodnak. Ezt az anyagot magyar szakemberek a hazai
				viszonyokhoz igazítva, magyar nyelven állították össze nektek. <br />
			</Text>

			<Text>
				<strong>Online elérhető</strong>. A képzés anyaga helytől függetlenül
				mindenkinek elérhető. Nem kell utazni, csak számítógép és internetelérés
				kell hozzá. A tananyag sok videót tartalmaz, ezzel is segítve a
				könnyebb megértést.
			</Text>
		</Section>
		<Section dark className="py-0" innerClassName="px-4 md:px-0 py-[1px]">
			<Subtitle className="px-6 mt-1">Milyen témákban segít a program?</Subtitle>

			<div className="py-10 lg:pb-[4px] flex flex-col items-center text-black relative">
				<div className="flex flex-row items-center py-8 lg:px-4 relative box-border h-[180px] lg:h-[290px] w-full">
					<div className="border-l-4 border-[#5891DB] absolute h-[140px] top-[-0px] hidden lg:block"></div>
					{/* <Line top={-140} flipped /> */}
					<img className="w-[600px] mx-auto my-10 lg:my-10" src="/img/info-2.png" alt="" />
					<Line top={140} small/>
				</div>
				<div className="w-full flex-row items-center p-10 text-3xl font-semibold hidden lg:flex">
					<div className="text-center w-[33%] text-[#623753]">
						Témakörök
					</div>
					<div className="flex-1" />
					<div className="p-4 text-center w-[33%] text-[#623753]">
						Hogyan?
					</div>
				</div>
				<Line top={334} flipped small/>
				<Temakor title={<>Digitális<br/>munkakörnyezet</>} flipped noTopLine
					left={<>
						Ebben a témakörben megismerheted
						a céges környezetben leggyakrabban
						használt, online csoportmunkát 
						támogató eszközöket 
						(MS Teams, OneNote, Planner).
					</>}
					right={<>
						Díjmentes, online anyagaink bárki
						számára elérhetőek a 
						https://tanulas.lehetkonnyebb.hu
						oldalon.
					    <br />+<br />
						A tananyagban szereplő MS Teams felületeten
						a gyakorlatban is kipróbálhatod az e-learninges
						anyagban bemutatottakat, valamint a program 
						többi résztvevőjével is egyeztethetsz.			

						{/*2021. december 4-én, 120 jelentkező 
						számára 4 órás, online, mentorált 
						képzést is biztosítunk térítésmentesen. */}
					</>} />
				<Temakor title={<>Pénzügyi<br />tudatosság</>} 
					left={<>
						Online videóink segítséget
						nyújtanak a pénzügyek tudatos
						menedzselésében. Az ide
						látogatók megismerhetik a
						legújabb banki megoldásokat, az
						elérhető otthonteremtési
						támogatásokat, a bankok és ATM-
						ek működését, a rendelkezésre
						álló hitelkonstrukciókat, illetve az
						öngondoskodási és megtakarítási
						lehetőségeket
					</>}
					right={<>
						Díjmentes, online anyagaink bárki
						számára elérhetőek a 
						https://tanulas.lehetkonnyebb.hu 
						oldalon.
						 <br />+<br />
						{/*A 2021. december 4-i térítésmentes, online, 
						mentorált képzési napon 120 résztvevő számára 
						közvetlen tájékoztatást is tartunk,*/} 
						Szakembereink e-mailben válaszolnak 
						a témához kapcsolódó kérdésekre.
					</>} />
				<Temakor title={<>Munkaerőpiaci<br />készségek</>} flipped noBottomLine
					left={<>
						Ennél a témakörnél mindenki
						tájékoztatást kaphat a jelenlegi 
						munkaerőpiaci helyzetről, valamint
						segítjük a szükséges készségek
						elsajátítását (pl: helyes önéletrajz,
						interjúzás stb.)
					</>}
					right={<>
						Díjmentes, online anyagaink bárki
						számára elérhetőek a 
						https://tanulas.lehetkonnyebb.hu 
						oldalon.
						<br /><br />
						
						{/* valamint a 2021. december 4-i online, mentorált 
						képzési napon 120 résztvevő számára 
						közvetlen előadással is segítjük az elmélyítést. */}
					</>} />
				<div className="border-l-4 border-[#5891DB] absolute h-[100px] left-[16px] bottom-[40px] hidden lg:block"></div>
			</div>
			{/* <img
				className="w-full h-full"
				src="/img/infografika2_3.png"
				alt="A képzés szerkezete"
			/> */}
			</Section>
			<Section dark className="pt-0" innerClassName="pt-[1px]">
			<Subtitle>Hogyan épül fel a program?</Subtitle>

			<Text>
				<strong>A különböző témakörökhöz kapcsolódó online tananyagainkat bármikor elkezdheted</strong>, saját
				időbeosztásod szerint elvégezheted. Attól függően, hogy az egyes
				részeket mennyire szeretnéd kipróbálni, begyakorolni, a tanulás 4-5 órát,
				de akár 10-12 órát is igénybe vehet. Ezt saját ütemednek megfelelően hétvégén, vagy
				hétköznap esténként is végezheted. Ha valamelyik rész jobban érdekel,
				akkor azzal foglalkozhatsz többet, ha valami kapcsán úgy érzed, hogy nem
				sok újat ad a tananyag, akkor azon gyorsabban végig mehetsz.
				<br />
				{/* <strong>
					2021. december 4-én mentorált, online, kiscsoportos képzési napot tartunk, összesen 120 fő részére. <br/>
				  
				</strong>{" "}
				A mentorált képzésen az online tananyagokhoz kapcsolódó, 
				<strong> gyakorlati példákon keresztül</strong> szeretnénk még jobban támogatni a tananyagok elmélyítését.
				
				 <br />
				 Képzési napunkon a <strong>digitális munkakörnyezet </strong>témakörben <strong>4 órás, kis csoportos</strong>, 
				 gyakorlati feladatokkal tarkított minikurzusokat, <strong>pénzügyi tudatosság</strong>, valamint <strong>munkavállalói készségek </strong> 
				 témakörökben pedig <strong>1-1 órás</strong> tájékoztató előadást tartunk. <br /><br />
				<strong>2021. december 4-i</strong> online, térítésmentes, <strong>mentorált képzési nap programja</strong> az alábbi (a programváltoztatás jogát fenntartjuk):
				<br/><br/>
				 09:30-09:45 Köszöntő, technikai tudnivalók<br/>
				 09:45-10:30 Digitális munkakörnyezet 1.<br/>
				 10:30-11:15 Munkavállalói készségek<br/>
				 11:15-12:00 Digitális munkakörnyezet 2.<br/>
				 12:00-12:45 Ebédszünet<br/>
				 12:45-13:30 Digitális munkakörnyezet 3.<br/>
				 13:30-14:15 Pénzügyi tudatosság<br/>
				 14:15-15:00 Digitális munkakörnyezet 4.<br/>
				 */}
			</Text>

			<Subtitle>Mire van szükséged a részvételhez?</Subtitle>

			<ul className="list-disc list-inside">
				<li>
					<strong>
						Számítógépre stabil internetkapcsolattal és Windows&nbsp;7 / Windows&nbsp;10 / Windows&nbsp;11
						operációs rendszerrel
					</strong>
					. <br />
				</li>
				<li>
					4-12 óra szabadidőre, attól függően, hogy mennyire szeretnél
					elmélyülni a tananyagban. <br />
				</li>
				{/* <li>
					Ha a mentorált képzésre jelentkezel, akkor a számítógépedhez
					mindenképpen legyen mikrofonod, de szerencsés, ha webkamerával is
					rendelkezel.
				</li> */}
			</ul>
		</Section>
		</>
	);
};

export default Programrol;
