import { useState } from "react";

const CookieConsent = () => {

	const [accepted, setAccepted] = useState(window.localStorage.lk_cookie_consent);

	const accept = () => {
		setAccepted(true)
		window.localStorage.setItem("lk_cookie_consent", "1")
	}

	if (accepted) return null

	return <div className="fixed w-[100vw] h-[48px] bottom-0 bg-[rgba(104,104,104,0.95)] text-white">
		<div className="container max-w-screen-lg flex w-full h-full items-center">
			<p className="flex-grow-[1]" >Oldalunkon sütiket használunk a forgalom elemzéséhez.</p>

			{/*
			<div className="cursor-pointer px-4 py-1 h-[32px] hover:underline mr-2 text-[rgba(255,255,255,0.6)]" onClick={accept}>
				Elutasítás
			</div>
			*/}

			<div className="bg-[#5C92E4] hover:bg-blue-400 cursor-pointer px-6 py-1 rounded-full h-[32px] " onClick={accept}>
				Elfogadom
			</div>

		</div>
	</div>
}

export default CookieConsent