import { Button } from ".";
import Section from "./Section";
import { Title } from "./typography";
import { goToAnchor } from "./Header"


const TanulasiPortal = () => {
  return (
    <>
      <Section dark>
        <Title id="tanulasiportal">Tanulási portál</Title>
        Ha már <a href="#jelentkezes" onClick={e => {
          e.preventDefault()
          goToAnchor("jelentkezes")
        }} className="underline whitespace-nowrap text-blue-700">jelentkeztél</a> az önálló tanulással elérhető anyagainkra, az e-mail címeddel és az általad beállított jelszóval 
        be tudsz jelentkezni a <strong>#Lehet könnyebb program</strong> <a href="https://tanulas.lehetkonnyebb.hu" target="_blank" rel="noreferrer"  className="underline whitespace-nowrap text-blue-700" >tanulas.lehetkonnyebb.hu</a> címen elérhető tanulási portálján, és hozzáférsz az ott található összes tananyaghoz.
        <div className="h-[15px]" />
        <a href="https://tanulas.lehetkonnyebb.hu" target="_blank" rel="noreferrer">
          <Button onClick={() => {}}>
            Már regisztráltam, továbblépek a Tanulási portálra
          </Button>
        </a>
      </Section>
    </>
  );
};

export default TanulasiPortal;
