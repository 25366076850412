import { useState, useEffect} from 'react'
import './App.scss'
import useScrollPosition from '@react-hook/window-scroll'
import {Header, Main, Footer, CookieConsent, Jelentkezes } from './components'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import MunkaeropiaciKeszsegek from './pages/MunkaeropiaciKeszsegek'

const checkVisible = (el: HTMLElement | null) => {
	if (!el) return false
	const rect = el.getBoundingClientRect()
	const elemTop = rect.top
	const elemBottom = rect.bottom
	const isVisible = elemTop < window.innerHeight && elemBottom >= 100
	return isVisible;
}

const menuItems = [
	//{label: 'Lehet könnyebb', target: "lehet-konnyebb"},
	{label: 'A programról', target: "programrol"},
	{label: 'Jelentkezés', target: "jelentkezes"},
	{label: 'Tanulási portál', target: "tanulasiportal"},
	{label: 'Rólunk', target: "rolunk"},
	{label: 'GYIK', target: "gyik"},
]


const App = () => {

	const [activeId, setActiveId] = useState("")
	const scrollY = useScrollPosition(10)


	useEffect(() => {
		const newId = menuItems.map(i => i.target).map((id) => {
			return { id, visible: checkVisible(document.getElementById(id)) }
		}).find(p => p.visible)?.id
		
		setActiveId(old => newId ?? old)
	}, [scrollY])

	return (
		<>
		<Router>
			<Header activeId={activeId} menuItems={menuItems} />
			<Routes>
				<Route path="/"  element={<Main />} />
				<Route path="jelentkezes" element={<Jelentkezes />} />
				<Route path="munkaeropiaci-kepzes" element={<MunkaeropiaciKeszsegek />} />
			</Routes>
		</Router>
		<CookieConsent />
		<Footer />
		</>
	)
}

export default App

