const Button = (props: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}) => (
  <div
    className={
      "bg-[#5C92E4] hover:bg-blue-400 text-white font-bold py-2 px-4 border-blue-700 hover:border-blue-500 inline-block cursor-pointer tracking-wider rounded-full " +
      props.className
    }
    onClick={props.onClick ? props.onClick : () => {}}
  >
    {props.children}
  </div>
);
export default Button;
