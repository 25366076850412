import { useEffect, useState } from "react";
import "./Banner.scss";

const slides = [
	{
		title: "a digitális munkavégzés",
		//img: 'https://image.shutterstock.com/z/stock-photo-profile-view-of-serious-woman-working-with-digital-tablet-cheerful-mature-business-woman-using-1773609953.jpg'
		//img: 'https://image.shutterstock.com/z/stock-photo-new-normal-office-lifestyle-asian-creative-designer-video-conference-collaboration-with-colleagues-1896530632.jpg'
		//img: 'https://image.shutterstock.com/z/stock-photo-horizontal-photo-female-mentor-explain-intern-corporate-software-online-task-point-at-pc-screen-1296270055.jpg'
		img: "/img/banner_1.png",
	},
	{
		title: "a pénzügyek kezelése",
		//img: 'https://image.shutterstock.com/shutterstock/photos/1925465429/display_1500/stock-photo-professional-tax-accountant-using-e-invoice-finance-software-1925465429.jpg'
		img: "/img/banner_2.png",
	},

	{
		title: "az álláskeresés",
		//img: 'https://www.shutterstock.com/hu/image-photo/smiling-mature-middle-aged-business-woman-1818524411'
		img: "/img/banner_3.png",
	},

];

const interval = 5;

const Banner = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	

	const next = () =>
		setActiveIndex((old) => (old >= slides.length - 1 ? 0 : old + 1));
	
	useEffect(() => {
		
		const i = window.setTimeout(next, interval * 1000);
		setTimeout((old) => {
			if (old) window.clearTimeout(old);
			return i;
		});
		
	}, [activeIndex]);

	useEffect(() => {
		return () => {
			setTimeout((old) => {
				window.clearTimeout(old);
				return 0;
			});
		};
	}, []);

	return (
		<>
			{ slides.map((slide, index) => <link key={index} rel="prefetch" href={slide.img} />)}
				
			<div className="banner mt-[64px] sm:h-[500px] h-[270px]">
				{slides.map((slide, index) => (
					<div
						key={index}
						className={`slide ${
							index === activeIndex
								? "active"
								: index < activeIndex
								? "left"
								: "right"
						}`}
					>
						<div
							className="bg-image"
							style={{ backgroundImage: `url(${slide.img})` }}
						></div>
						<div className="container max-w-screen-lg relative h-full sm:px-2">
							<div className="content">
								<div className="title sm:text-[3.5rem] sm:leading-[84px] text-[2.7rem]">
									<span className="sm:px-[25px] px-[15px]">Lehet</span>
									<br />
									<span className="sm:px-[25px] px-[15px]">könnyebb</span>
								</div>
								<h2 className="sm:text-[2rem] sm:leading-[48px] text-[1.44rem]">{slide.title}</h2>
							</div>
							<img src="/img/lehetkonnyebb-box.png" alt="" className="logo"/>
						</div>
					</div>
				))}
			</div>
			<div className="logos">
				<div className="container max-w-screen-lg">
					<a href="https://http-alapitvany.hu" target="_blank" rel="noopener noreferrer">
						<img src="/img/http.png" alt="HTTP Alapítvány" className="http" />
					</a>
					<a href="https://www.microsoft.com/hu-hu" target="_blank" rel="noopener noreferrer">
						<img src="/img/microsoft.png" alt="Microsoft" className="microsoft" />
					</a>
					<a href="https://www.otpbank.hu/" target="_blank" rel="noopener noreferrer">
						<img src="/img/otp-bank-logo_attetszo.png" alt="OTP Bank" className="otp" />
					</a>
					<a href="https://hu.trenkwalder.com/" target="_blank" rel="noopener noreferrer">
						<img src="/img/TKW_wordmark_logo.png" alt="Trenkwalder" className="trenkwalder" />
					</a>
				</div>
			</div>
		</>
	);
};

export default Banner;
