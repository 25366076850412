import { useState } from "react";
import { useNavigate } from "react-router";

interface IHeaderProps {
	activeId: string;
	menuItems: { label: string; target: string }[];
}

export const goToAnchor = (id: string) => {
	const el = document.getElementById(id);
	if (el)
		window.scrollTo({
			behavior: "smooth",
			top:
				el.getBoundingClientRect().top +
				window.scrollY - 100,
		});
}

const Header = (props: IHeaderProps) => {
	const { activeId, menuItems } = props;
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();
	return (
		<>
		<header className="bg-[#4e233f] text-white fixed w-screen z-40 top-0">
			<nav className="container max-w-screen-lg">
				<div className="flex items-center h-16 px-4">
					<div className="justify-end w-full">
					
					<div className="-mr-2 -my-2 sm:hidden flex items-center w-full" onClick={()=>setMenuOpen(true)}>
							<button type="button" className="rounded-md ml-0 p-2 inline-flex items-center justify-center text-gray-400 hover:text-[#5C92E4]" aria-expanded="false">
								<span className="sr-only">Open menu</span>
								<svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
								</svg>
							</button>
							<img onClick={(e) => navigate("/", {state: {anchor: "lehet-konnyebb"}})} src="/img/lehetkonnyebb.png" alt="Lehet könnyebb" className="ml-3 h-[30px] mt-[1px] pr-2 cursor-pointer" />
						</div>
						<div className="hidden sm:block">
							<div className="flex space-x-4">
								<img onClick={(e) => navigate("/", {state: {anchor: "lehet-konnyebb"}})} src="/img/lehetkonnyebb.png" alt="Lehet könnyebb" className="h-[30px] mt-[1px] pr-2 cursor-pointer" />
								{menuItems.map((item, index) => {
									const isActive = activeId === item.target;
									return (
										<a
											onClick={(e) => {
												e.preventDefault();
												navigate('/', {state: {anchor: item.target}});
											}}
											key={index}
											href={`#${item.target}`}
											className={`${
												!isActive
													? "text-gray-300 hover:text-white"
													: "bg-gray-900 text-white"
											} px-3 py-2 rounded-md text-[12px] font-semibold uppercase tracking-widest`}
											aria-current="page"
										>
											{item.label}
										</a>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
		<div onClick={()=>setMenuOpen(false)} className={`transition-all w-[100vw] h-[100vh] fixed z-50 top-0 ${menuOpen ? 'left-0' : 'left-[-100%]'}`}>
			<div onClick={(e)=>e.stopPropagation()} className="w-[300px] h-[100%] bg-[#e5ebf5] max-w-[100%]">
				{menuItems.map((item, index) => {
					const isActive = activeId === item.target;
					return (
						<div 
							key={index}
							onClick={(e) => {
								e.preventDefault();
								navigate('/', {state: {anchor: item.target}});//goToAnchor(item.target);
								setMenuOpen(false);
							}} 
							className={`w-[100%] p-4 hover:bg-gray-400 cursor-pointer ${isActive?'bg-gray-400': ''}`}
						>
							 {item.label}
						</div>
					)
				})}
			</div>
		</div>
		</>
	);
};

export default Header;
