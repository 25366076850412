/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";

const enabled = true // Boolean(window.localStorage.test)


export default function Form(props: {open: boolean, setOpen: (open: boolean) => void, mentored: boolean}) {
	const cancelButtonRef = useRef(null)
	const recaptchaRef = useRef<ReCAPTCHA>(null)

	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [age, setAge] = useState('')
	const [gender, setGender] = useState('')
	const [unemployed, setUnemployed] = useState<boolean|null>(null)
	const [unemployedSince, setUnemployedSince] = useState('')
	const [source, setSource] = useState('')
	const [feedback, setFeedback] = useState(true)

	const [isSuccess, setIsSuccess] = useState(false)
	//const [error, setError] = useState<string|false>(false)
	const [emailTaken, setEmailTaken] = useState(false)
	const [reCaptchaError, setReCaptchaError] = useState(false)
	const [loading, setLoading] = useState(false)

	const [reCaptcha, setReCaptcha] = useState<string|null>(null)

	const submit = async () => {
		//alert('submit')
		if (!reCaptcha) {
			//alert('empty recap')
			setReCaptchaError(true)
			return
		}
		
		setLoading(true)
		//const token = await recaptchaRef?.current?.executeAsync()
		//console.log('token', token)
		const data = { firstName, lastName, email, age, gender, unemployed, unemployedSince, source, token: reCaptcha, mentored: props.mentored, feedback }
		axios.post(window.localStorage.dev ? "http://localhost:4000/api/register" : "/api/register", data).then(() => {
			props.setOpen(false)
			setIsSuccess(true)
			setFirstName('')
			setLastName('')
			setEmail('')
			setAge('')
			setGender('')
			setUnemployed(null)
			setUnemployedSince('')
			setSource('')
			setFeedback(true)
		}).catch((err) => {
			if (err.response.status === 422) {
				setEmailTaken(true)
			} else {
				alert("Ismeretlen hiba történt. Kérjük próbálja újra.")
			}
		}).finally(() => {
			setLoading(false)
			recaptchaRef.current?.reset()
			setReCaptcha(null)
		})
	}

	return (
		<>

		<Transition.Root show={isSuccess} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-none text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Sikeres jelentkezés!
										</Dialog.Title>
										
										<div className="mt-2">
														<p className="text-sm text-gray-500">
															Köszönjük, hogy jelentkeztél a programra. Küldtünk egy emailt a megadott címre, amiben további információkat találsz.
														</p>
													</div>
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className="w-full inline-flex justify-center rounded-none border border-transparent shadow-sm px-4 py-2 bg-blue-500 hover:bg-blue-400 text-base font-medium text-white focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => setIsSuccess(false)}
								>
									Bezárás
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
		
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => {}}>
				<form onSubmit={e => {
					e.preventDefault()
					submit()
				}}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
						>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					
					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:mb-8 sm:mt-20 sm:align-middle sm:max-w-lg sm:w-full">
							{ loading && 
							<>
								<div className="bg-black opacity-40 absolute w-full h-full">
									
								</div>
								<div className="absolute w-full h-full">
									<div className="h-20 w-20 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
										<div
											className="loader ease-linear rounded-full
											border-4 border-t-4 border-gray-200
											h-full
											w-full
											"
										></div>
									</div>
								</div>
							</>}
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:text-left">
									
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											{props.mentored ? 'Jelentkezés mentorált kurzusra' : 'Jelentkezés önálló tanulásra'}
										</Dialog.Title>

										{ enabled && <>
										
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												{ props.mentored ? <span>
													Köszönjük jelentkezésedet mentorált kurzusunkra!
													<br/>
													Az űrlap kitöltése után azonnal kapsz egy visszaigazoló e-mailt a megadott e-mail címre. Az e-mailben tájékoztatunk a mentorált képzéssel kapcsolatos további tudnivalókról, valamint a tananyaghoz történő csatlakozásról.
													<br/>
													Reméljük, hamarosan találkozunk!
												</span> : <span>
													Köszönjük jelentkezésedet önálló tanulásos kurzusunkra!
													<br/>
													Az űrlap kitöltése után azonnal kapsz egy visszaigazoló e-mailt az itt megadott e-mail címre. Az abban kapott linkkel tudsz hozzájutni tananyagainkhoz, a felületre történő belépéshez már csak egy általad választott jelszót kell majd megadnod.
													<br/>
													Sok sikert kívánunk!
												</span>}
											</p>
										</div>
										
										<div className="mt-6">
											<div className="grid grid-cols-6 gap-2">
												<div className="col-span-6 sm:col-span-3">
													<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">Vezetéknév</label>
													<input value={lastName} onChange={e=>setLastName(e.target.value)} required type="text" name="last-name" id="last-name" autoComplete="last-name" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-3 focus:outline-none" />
												</div>

												<div className="col-span-6 sm:col-span-3">
													<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Keresztnév</label>
													<input value={firstName} onChange={e=>setFirstName(e.target.value)} required type="text" name="first-name" id="first-name" autoComplete="given-name" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-3 focus:outline-none" />
												</div>

												<div className="col-span-6">
													<label htmlFor="email" className={`block text-sm font-medium text-gray-700 ${emailTaken && 'text-red-500'}`}>Email cím</label>
													<input value={email} onChange={e=>{setEmail(e.target.value); setEmailTaken(false)}} required type="email" name="email" id="email" autoComplete="email" className={`mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-3 focus:outline-none ${emailTaken && 'border-red-300'}`} />
													{ emailTaken && <p className="text-red-500 text-xs italic">Ez az email cím már foglalt.</p> }
												</div>

												<div className="col-span-6">
													<label htmlFor="age" className="block text-sm font-medium text-gray-700">Kor</label>
													<select value={age} onChange={e=>setAge(e.target.value)} required name="age" id="age" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-2 focus:outline-none">
														<option hidden></option>
														{
															[
																"18 év alatt",
																"18-25",
																"26-35",
																"36-45",
																"46-55",
																"56-65",
																"65 év felett"
															].map((o, i) => <option key={i} value={o}>{o}</option>)
														}
													</select>
												</div>

												<div className="col-span-6">
													<label htmlFor="gender" className="block text-sm font-medium text-gray-700">Nem</label>
													<select value={gender} onChange={e=>setGender(e.target.value)} required name="gender" id="gender" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-2 focus:outline-none">
														<option hidden></option>
														{
															[
																"Férfi",
																"Nő",
																"Nem kívánok válaszolni"
															].map((o, i) => <option key={i} value={o}>{o}</option>)
														}
													</select>
												</div>

												<div className="col-span-6">
													<label htmlFor="email" className="block text-sm font-medium text-gray-700">Jelenleg álláskereső vagy?</label>
													<input checked={unemployed === true} onChange={e => setUnemployed(true)} required type="radio" name="unemployed" id="unemployed_y" className="mt-2 sm:text-sm ml-1" />
													<label htmlFor="unemployed_y" className="text-sm text-gray-700 pl-2 mr-6">Igen</label>
													<input checked={unemployed === false}  onChange={e => setUnemployed(false)}  required type="radio" name="unemployed" id="unemployed_n" className="mt-2 sm:text-sm" />
													<label htmlFor="unemployed_n" className="text-sm text-gray-700 pl-2">Nem</label>
												</div>

												{ unemployed === true &&

													<div className="col-span-6">
														<label htmlFor="unemployedSince" className="block text-sm font-medium text-gray-700">Mióta vagy álláskereső?</label>
														<select value={unemployedSince} onChange={e=>setUnemployedSince(e.target.value)} required name="unemployedSince" id="unemployedSince" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-2 focus:outline-none">
															<option hidden></option>
															{
																[
																	"Kevesebb, mint 1 hónapja",
																	"1-3 hónapja",
																	"3-6 hónapja",
																	"6-12 hónapja",
																	"1-2 éve",
																	"Több, mint 2 éve"
																].map((o, i) => <option key={i} value={o}>{o}</option>)
															}
														</select>
													</div>
												}

												<div className="col-span-6 mb-2">
													<label htmlFor="source" className="block text-sm font-medium text-gray-700">Honnan értesültél a programról?</label>
													<select value={source} onChange={e=>setSource(e.target.value)} required name="source" id="source" className="mt-1 focus:border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-200 rounded-none border-2 py-2 px-2 focus:outline-none">
														<option hidden></option>
														{
															[
																"Internetes cikkből",
																"Munkaügyi Központ által",
																"Közösségi médiából",
																"Ismerőstől",
																"Iskolából / tanáromtól / kollégámtól",
																"Egyéb"
															].map((o, i) => <option key={i} value={o}>{o}</option>)
														}
													</select>
												</div>

												<div className="col-span-6 mb-2">
													<label className="inline-flex items-center">
														<input type="checkbox" className="form-checkbox" checked={feedback} onChange={e => setFeedback(e.target.checked)}/>
														<span className="ml-4">Hozzájárulok, hogy a kurzus hasznosulásával kapcsolatban egy rövid, maximum 5 perc kitöltési idejű, online űrlappal, valamint esetleges további térítésmentes képzési lehetőségekkel online megkeressenek a szervezők.</span>
													</label>
												</div>

												<div className="col-span-6 mb-2">
													<label className="inline-flex items-center">
														<input type="checkbox" className="form-checkbox" required/>
														<span className="ml-4">Elfogadom az <a className="text-blue-500 hover:underline" href="/Adatkezelési szabályzat_Lehet_konnyebb_tanulas_lehetkonnyebb_hu_220701 .pdf" target="_blank" rel="noopenere noreferrer">Adatkezelési szabályzatban</a> foglaltakat.<sup>*</sup></span>
													</label>
												</div>


												
												
												<ReCAPTCHA
													ref={recaptchaRef}
													size="normal"
													sitekey="6Lenk6AcAAAAAGgVvDFsZw2XJy-92FtqtRcplK8n"
													onChange={value => {setReCaptcha(value); setReCaptchaError(false)}}
												/>
												<div className="col-span-6 mb-2">
													{ reCaptchaError && <p className="text-red-500 text-sm">Jelölje be a fenti jelölőnégyzetet.</p> }
												</div>
												
											</div>
										</div>
										</>}
										{!enabled && <div className="mt-6">
											<p className="text-sm text-red-600">
												A jelentkezés még nem elérhető. Kérjük, nézz vissza később.
											</p>
										</div>}
									</div>
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
								{
									enabled && <button
										type="submit"
										className="w-full inline-flex justify-center rounded-none border border-transparent shadow-sm px-4 py-2 bg-blue-500 hover:bg-blue-400 text-base font-medium text-white focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
									>
										Jelentkezés
									</button>
								}
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-none border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => props.setOpen(false)}
									ref={cancelButtonRef}
								>
									{enabled ? "Mégsem" : "Bezárás"}
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
				</form>
			</Dialog>
		</Transition.Root>
		</>
	)
}
