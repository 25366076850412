import "./Card.scss";

interface ICardPropsType {
  imageUrl: string;
  imageAlt: string;
  cardColor: string;
  children: JSX.Element;
}

const Card = (props: ICardPropsType) => {
  const { imageUrl, imageAlt, cardColor } = props;
  const cardStyle = {
   border: "1px solid lightgrey",
   borderBottom: "5px solid " + cardColor,
  };

  const cardImageStyle = {
    backgroundColor: cardColor,
  };

  return (
    <div className="card mb-1 " style={cardStyle}>
      <div className="card-image rounded-lg" >
        <div style={cardImageStyle} className="">
        <img src={imageUrl} className="w-max " alt={imageAlt}/>
          <div className="p-3 font-bold  text-white">
            {imageAlt}
          </div>
        </div>
        <div className="p-3" >{props.children}</div>
      </div>
      
    </div>
  );
};

export default Card;
