import Section from "./Section";
import { Title, Text } from "./typography";
import { Card } from "./";
const Rolunk = () => {
  return (
    <Section>
      <Title id="rolunk">Rólunk</Title>
      <div className="grid auto-cols-1 md:grid-cols-2 gap-4">
        <Card
          imageUrl="/img/rolunk_http.png"
          imageAlt="HTTP Alapítvány"
          cardColor="#5C92E4"
        >
          <Text>
            Hisszük, hogy a digitális technológiákban és mindezek oktatásában
            szerzett tapasztalatunkkal hatékony támaszai lehetünk minden
            tanulni- és fejlődni vágyó érdeklődőnek.
          </Text>
        </Card>
        <Card
          imageUrl="/img/microsoft_kicsi.png"
          imageAlt="Microsoft"
          cardColor="#673F58"
        >
          <Text>
            Már ma a holnap technológiáját fejlesztjük, a holnap munkaköreit és
            munkakörnyezetét teremtjük meg. A digitalizáció tehermentesíti az
            embert, hogy kreativitását alkotásra tudja fordítani. A digitális
            technológiák több szabadidőt és lehetőséget adnak az embereknek.
          </Text>
        </Card>

        <Card
          imageUrl="/img/otp_kicsi.png"
          imageAlt="OTP Bank"
          cardColor="#016E51"
        >
          <Text>
          Számunkra fontos, hogy szakértelmünket jó ügyek szolgálatába állíthassuk. 
          Az OTP Bank meghatározó pénzügyi szolgáltatóként feladatának érzi az alapvető 
          pénzügyi ismeretek átadását fiatalok és felnőttek számára, felkészítve ezzel 
          őket a felelős pénzügyi döntéshozatalra. 
          Célunk a pénzügyi kultúra széles körű megteremtése, illetve terjesztése.
          </Text>
        </Card>

        <Card
          imageUrl="/img/trenkwalder_kicsi.png"
          imageAlt="Trenkwalder"
          cardColor="#EC0529"
        >
          <Text>
          A Trenkwalder-nél mi úgy gondoljuk, mindenkinek joga van a 
          szakmai elégedettségre és álmainak megvalósítására a munkaerőpiacon. 
          A Trenkwalder Magyarországon már több mint két évtizede megbízható 
          partnerként támogatja azokat, akik szeretnék építeni karrierjüket.

          </Text>
        </Card>
      </div>
    </Section>
  );
};

export default Rolunk;
