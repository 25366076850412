import { Link } from "react-router-dom";
import { JelentkezesContent } from "../components/Jelentkezes";
import Section from "../components/Section";
import { Title, Text } from "../components/typography";


const MunkaeropiaciKeszsegek = () => {

    return (
        <> 
        <Section>
            <Title id="munkaeropiaciKeszsegek">Munkaerőpiaci készségek</Title>
            <img src="/img/banner_3.png" alt="" />
            <Text className="mt-5">
                A <Link to="/" state={{anchor: "programrol"}} className="text-blue-600/100">Lehet könnyebb program</Link> ingyenes <strong>Munkaerőpiaci készségek</strong> online tananyagában tájékoztatást kaphatsz a jelenlegi  munkaerőpiaci helyzetről, valamint segítjük a szükséges készségek elsajátítását (pl: helyes önéletrajz, interjúzás stb.)
            </Text> 
            <JelentkezesContent />
        </Section>
        </>
    )
}

export default MunkaeropiaciKeszsegek;