import { Button } from ".";
import Section from "./Section";
import { Title, Subtitle } from "./typography";
import Form from "./Form"
import JelentkezesInfoModal from "./JelentkezesInfoModal";
import { useState } from "react";

const Jelentkezes = () => {

  return (
    <>
      <Section>
        <Title id="jelentkezes">Jelentkezés</Title>
        <JelentkezesContent />
      </Section>
    </>
  );
};

export const JelentkezesContent = () => {
  const [open, setOpen] = useState(false)
  const [mentored, setMentored] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  return (
  <>
    <Subtitle>Hogyan jelentkezhetsz?</Subtitle>
    <div className="">
      <div>
        <strong>
        Az online tananyag</strong> eléréséhez, kérjük, kattints a <strong>„Jelentkezés önálló tanulásra”</strong> gombra és
        töltsd ki a felugró űrlapot. Ezt követően a megadott e-mail címre küldjük ki a csatlakozási linket, ahol egy általad
        választott jelszó megadása után azonnal el is érheted anyagainkat.
        Ha esetleg nem találod az üzenetet, kérjük, hogy nézd át a spam/promóciók mappáidat is!

        <br />
      </div>
      <div className="h-[15px]" />
      <Button onClick={() => {
        //setInfoModalOpen(true)
        setMentored(false)
        setOpen(true)
      }}>Jelentkezés önálló tanulásra</Button>
      <div className="h-[15px]" />

    </div>

    <Form mentored={mentored} open={open} setOpen={setOpen} />
    <JelentkezesInfoModal open={infoModalOpen} setOpen={setInfoModalOpen} />
  </>
  )
}
export default Jelentkezes;

    {/*           <div>
        Ha szeretnél <strong>mentorált képzésben is részesülni</strong>,
        ahol <strong>mentoraink segítenek egy-egy ismeret begyakorlásában</strong>,
        pontosabb megértésében, úgy kattints a <strong> „Jelentkezem a mentorált kurzusra + online anyagra”</strong> gombra.
        Ebben az esetben természetesen ugyanúgy azonnal hozzájuthatsz online tananyagainkhoz,
        de ezen kívül felveszünk a mentorált képzésre jelentkezők közé is.
        A továbbiakról a regisztrációt megerősítő, válasz e-mailben tájékoztatunk. <br />
        <strong>Jelentkezési határidő: 2021. november 30.</strong> <br />
        Kérjük, hogy a nagy érdeklődésre való tekintettel, akkor jelentkezz mentorált képzésünkre,
        ha úgy érzed, hogy bejutás esetén biztosan részt tudsz venni 2021. december 4-én az online kurzuson.<br />
      </div> */}
    {/*         <div className="h-[15px]" />
    <Button onClick={() => {
      setMentored(true)
      setOpen(true)
    }}>Jelentkezem a mentorált kurzusra + online tananyagra</Button> */}
